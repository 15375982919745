import { useStaticQuery, graphql } from "gatsby"
export const  useWeddingsData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesWeddingsYaml {
          weddingsContent
          heading {
            headingText
            subHeadingText
            subHeadingMaxWidth
            headingImageAbove {
              childImageSharp {
                fluid(maxWidth: 1300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return data.pagesWeddingsYaml
}